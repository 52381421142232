<template>
  <div class="secondary-dns-primary-nameservers">
    <div class="name-server-sets">
      <select v-model="selectedNameServerSet" @change="onSelectionChange($event)" class="form-select">
        <option disabled value="">Please select one</option>
        <option v-for="nameServerSet in nameServerSets" v-bind:key="nameServerSet.id" :value="nameServerSet.id" v-html="nameServerSet.label" />
      </select>
    </div>
    <div class="records">
      <ul>
        <li v-for="(nameServer, index) in nameServers" v-bind:key="`nameServer-${index}`">{{ nameServer }}</li>
      </ul>
      <input type="hidden" name="secondary_dns_configuration[name_servers_list]" :value="nameServersFormFieldValue" />
    </div>
  </div>
</template>

<script>

export default {
  props: ['nameServerSets'],
  data () {
    return {
      selectedNameServerSet: '',
      nameServers: null,
      nameServersFormFieldValue: null
    };
  },
  methods: {
    onSelectionChange: function (event) {
      this.nameServers = [];
      this.nameServersFormFieldValue = null;
      if (this.selectedNameServerSet != null)
        for (const nameServerSet of this.nameServerSets)
          if (nameServerSet.id === this.selectedNameServerSet) {
            this.nameServers = nameServerSet.name_servers;
            this.nameServersFormFieldValue = nameServerSet.name_servers.join('\n');
            break;
          }
    }
  }
};
</script>
